import { JobGroupDto } from '../../api/dto/JobGroupDto';
import { JobDto } from '../../api/dto/JobDto';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { enableMapSet, produce } from 'immer';

enableMapSet();

interface Actions {
  setJobGroupListData: (data: JobGroupDto[]) => void;
  toggleValue: (value: string) => void;
  applyValue: (value: string[]) => void;
  isSameAsOrigin: () => boolean;
}

interface Data {
  jobMap: Map<
    string,
    JobGroupDto & {
      jobList: JobDto[];
    }
  >;
  value: Set<string>;
  originData: string[];
}

const defaultData: Data = {
  jobMap: new Map(),
  value: new Set(),
  originData: [],
};

interface ISettingsMatchStore {
  data: Data;
  actions: Actions;
}

export const useSettingsMatch = create<ISettingsMatchStore>()(
  immer((set, get) => ({
    data: defaultData,
    actions: {
      setJobGroupListData: (data) => {
        data.forEach((v) => {
          set((state) => {
            state.data.jobMap.set(v.code, v);
          });
        });
        // set origin data to value that job code which is not matchable
        set(({ data }) => {
          const originValue = new Set<string>();
          data.jobMap.forEach((v) => {
            v.jobList.forEach((j) => {
              if (!j.matchableType) {
                originValue.add(j.code);
              }
            });
          });
          data.originData = Array.from(originValue);
          data.value = new Set(originValue);
        });
      },
      toggleValue: (value) => {
        set(({ data }) => {
          if (data.value.has(value)) {
            data.value.delete(value);
          } else {
            data.value.add(value);
          }
        });
      },
      applyValue: (value) => {
        set(({ data }) => {
          data.originData = value;
          data.value = new Set(value);
        });
      },
      isSameAsOrigin: () => {
        const current = Array.from(get().data.value.values());
        const origin = get().data.originData;
        return current.length === origin.length && current.every((v) => origin.includes(v));
      },
    },
  }))
);
