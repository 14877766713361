// Created by kdw0601 on 2022-10-17
import styles from './MatchStatisticsList.module.scss';
import classNames from 'classnames/bind';
import React, { Fragment } from 'react';
import useMatchStatisticsList from './useMatchStatisticsList';
import InputText from '../../common/input/InputText';
import Table from '../../common/table/Table';
import Pagination from '../../common/pagination/Pagination';
import Empty from '../../common/empty/Empty';
import { COMPOSITION_TYPE_LIST } from '../../contract/list/AddPointModal/useAddPointModal';
import { MatchActionTypeText } from '../../../repositories/matchStatistics/types/MatchActionType';

const cx = classNames.bind(styles);

const MatchStatisticsList = () => {
  const { data, search, setSearch, setPagination } = useMatchStatisticsList();

  return (
    <>
      <div className={cx('toolbar')}>
        <div className={cx('search')}>
          <InputText
            placeholder={'기업명, 구직자명, JOBDA ID 검색'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {/*<ButtonCommon type={'gray-line'}>엑셀 다운로드</ButtonCommon>*/}
        </div>
      </div>
      <div className={cx('table-wrap')}>
        <Table className={cx('table')}>
          <thead>
            <tr>
              <th>기업명</th>
              <th>사용자명</th>
              <th>포지션명</th>
              <th>포지션 ID</th>
              <th>구직자명</th>
              <th>구직자 JOBDA ID</th>
              <th>행동</th>
              <th>행동 시간</th>
            </tr>
          </thead>
          <tbody>
            {data?.contents.map((v) => (
              <tr key={v.id}>
                <td>{v.companyName}</td>
                <td>{v.memberName}</td>
                <td>{v.positionName}</td>
                <td>{v.positionId}</td>
                <td>{v.applicantName}</td>
                <td>{v.applicantJobdaId}</td>
                <td>{MatchActionTypeText[v.action]}</td>
                <td>{v.actionDateTime}</td>
              </tr>
            ))}
            {!data?.contents.length && (
              <tr>
                <td colSpan={7 + COMPOSITION_TYPE_LIST.length * 3}>
                  <Empty message={'현황이 없습니다'} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {data?.pageMeta && (
        <Pagination
          page={{
            size: data.pageMeta.size,
            page: data.pageMeta.page,
            totalPageCount: data.pageMeta.totalPageCount,
            totalItemCount: data.pageMeta.totalItemCount,
          }}
          pageHandler={setPagination}
        />
      )}
    </>
  );
};

export default MatchStatisticsList;
