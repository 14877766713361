// Created by kdw0601 on 2024-07-04
import styles from './ProductCompositionTable.module.scss';
import classNames from 'classnames/bind';
import { useProductDetailStore } from './store/useProductDetailStore';
import React, { useEffect } from 'react';
import ProductDetailV1ItemRow from './ProductDetailV1ItemRow';
import ProductDetailV1EtcRow from './ProductDetailV1EtcRow';
import ProductCompositionType from '../../../repositories/product/detail/ProductCompositionType';
import ProductDetailV1NCCRequestRow from './ProductDetailV1NCCRequestRow';
import ProductType from '../../../repositories/usage/vo/ProductType';

const cx = classNames.bind(styles);

const compositionOrder1: ProductCompositionType[] = [
  'POSITION_CREATE',
  'SPACE_CREATE',
  'POSITION_OPEN',
  'APPLICANT_QUANTITY',
  'BUILDER',
  'EVALUATION_CUSTOMIZATION',
];

const compositionOrder2: ProductCompositionType[] = [
  'STEP_EVALUATOR',
  'MANUAL_APPLICANT',
  'RESUME_DOWNLOAD',
  'NCC_REPORT_DOWNLOAD',
  'EXCEL_DOWNLOAD',
  'SEND_LARGE_SCALE_MESSAGE',
  'SMS',
  'EMAIL',
  'AUTO_MATCHING',
  'SUBSCRIBE',
  'THEME',
  'SMART_SORT',
];

interface Props {
  showUsage?: boolean;
  showQuantityPrice?: boolean;
  editableQuantityPrice?: boolean;
}

const ProductDetailV1Composition = (props: Props) => {
  const { showUsage, showQuantityPrice, editableQuantityPrice } = props;
  const list = useProductDetailStore((state) => state.data.compositionList);
  const locked = useProductDetailStore((state) => state.data.locked);
  const reset = useProductDetailStore((state) => state.actions.reset);
  const productType = useProductDetailStore((state) => state.data.type);

  useEffect(() => {
    return reset;
  }, []);

  if (!list.length) {
    return null;
  }

  return (
    <table className={cx('composition-table')}>
      <thead>
        <tr>
          <th>항목</th>
          <th>사용여부</th>
          <th>무제한 여부</th>
          <th>초과 허용 여부</th>
          {showQuantityPrice && (
            <>
              <th>수량</th>
              <th>단가</th>
              <th>계</th>
            </>
          )}
          {showUsage && (
            <>
              <th>계약 수량</th>
              <th>사용 수량</th>
              <th>잔여 수량</th>
              <th>초과 수량</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {productType === ProductType.CUSTOM_V1 && (
          <>
            {compositionOrder1.map((v) => (
              <ProductDetailV1ItemRow
                key={v}
                type={v}
                disabled={locked}
                showUsage={showUsage}
                showQuantityPrice={showQuantityPrice}
                editableQuantityPrice={editableQuantityPrice}
              />
            ))}
            <ProductDetailV1NCCRequestRow
              showUsage={showUsage}
              showQuantityPrice={showQuantityPrice}
              editableQuantityPrice={editableQuantityPrice}
            />
            {compositionOrder2.map((v) => (
              <ProductDetailV1ItemRow
                key={v}
                type={v}
                disabled={locked}
                showUsage={showUsage}
                showQuantityPrice={showQuantityPrice}
                editableQuantityPrice={editableQuantityPrice}
              />
            ))}
          </>
        )}
        {productType !== ProductType.CUSTOM_V1 &&
          list.map((v) => (
            <ProductDetailV1ItemRow
              key={v.type}
              type={v.type}
              disabled={locked}
              showUsage={showUsage}
              showQuantityPrice={showQuantityPrice}
            />
          ))}

        <ProductDetailV1EtcRow editable={editableQuantityPrice} />
      </tbody>
    </table>
  );
};

export default ProductDetailV1Composition;
